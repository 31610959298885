import { atomWithStorage } from 'jotai/utils';

const initialPreferences = {
    contrast: false,
    showSettingsPanel: false,
    startZoomFactor: 1,
    backgroundColorSecondary: '#000000',
    zoom: 1,
    detailZoom: 1,
    titleZoom: 1,
    subheaderZoom: 0.8,
    timeZoom: 1,
    chipZoom: 0.5,
    reminderZoom: 1,
    arrangements: false,
    clockZoom: 1,
    startClockZoom: 1,
    notesZoom: 1,
    supervisionZoom: 1,
    showNotes: true,
    showSupervision: true,
    // Add more preferences as needed
};

export const preferencesAtom = atomWithStorage('preferences', initialPreferences);