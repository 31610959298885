import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link as RouterLink } from 'react-router-dom';
import { Container, CssBaseline, Link, ThemeProvider } from '@mui/material';
import { Amplify, Auth } from 'aws-amplify';
import aws_exports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CookieConsent from "react-cookie-consent";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Home from './Pages/Home';
import NavBar from './Components/NavBar';
import ColorModeContext from './Components/ColorModeContext';
import ProtectedRoute from './Pages/ProtectedRoute';
import useColorMode from './hooks/useColorMode';
import useLoggedIn from './hooks/useLoggedIn';
import { LicenseInfo } from '@mui/x-license-pro';
import InvigilationOptions from './Components/Invigilation/InvigilationOptions';
import AnalyserProtectedRoute from './Pages/AnalyserProtectedRoute';
import useUserData from './hooks/useUserData';
import AnalyserSuccessPage from './Pages/AnalyserSuccessPage';
import AnalyserCancelPage from './Pages/AnalyserCancelPage';

LicenseInfo.setLicenseKey('482e188f2e99572f4a4447fc5f2e8da0Tz03NzI5NCxFPTE3Mjk3NzY3MzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const LazyAbout = React.lazy(() => import('./Pages/About'))
const LazyAccount = React.lazy(() => import('./Pages/Account'))
const LazyAup = React.lazy(() => import('./Pages/Aup'))
const LazyCountdown = React.lazy(() => import('./Pages/Countdown'))
const LazyContact = React.lazy(() => import('./Pages/Contact'))
const LazyCookies = React.lazy(() => import('./Pages/Cookies'))
const LazyGuides = React.lazy(() => import('./Pages/Guides'))
const LazyInvigilation = React.lazy(() => import('./Pages/Inviligation'))
const LazyMyInvigilators = React.lazy(() => import('./Pages/Invigilators'))
const LazyMyComponents = React.lazy(() => import('./Pages/Components'))
const LazyMyExams = React.lazy(() => import('./Pages/MyExams'))
const LazyMyRooms = React.lazy(() => import('./Pages/Rooms'))
const LazyMyCycles = React.lazy(() => import('./Pages/Cycles'))
const LazyMyStudents = React.lazy(() => import('./Pages/Students'))
const LazyMySubjects = React.lazy(() => import('./Pages/Subjects'))
const LazyMyIndividualCycle = React.lazy(() => import('./Pages/IndividualCycle'))
const LazyMyBoards = React.lazy(() => import('./Pages/Boards'))
const LazyMyScheduler = React.lazy(() => import('./Pages/Scheduler'))
const LazyMySchoolSetup = React.lazy(() => import('./Pages/SchoolSetup'))
const LazyNotfound = React.lazy(() => import('./Pages/Notfound'))
const LazyPrivacy = React.lazy(() => import('./Pages/Privacy'))
const LazySignIn = React.lazy(() => import('./Components/SignIn'))
const LazySuccess = React.lazy(() => import('./Pages/Success'))
const LazySupport = React.lazy(() => import('./Pages/Support'))
const LazyTerms = React.lazy(() => import('./Pages/Terms'))
const LazyTrial = React.lazy(() => import('./Pages/Trial'))
const LazyPrintCandidateLabels = React.lazy(() => import('./Components/Scheduler/Printing/Templates/Documents/PrintCandidateLabels'))
const LazyMyDisplayList = React.lazy(() => import('./Pages/Displays'))
const LazyMyResults = React.lazy(() => import('./Pages/Analyser'))
const LazyMyIBHeadlines = React.lazy(() => import('./Components/Analyser/IB/Headlines/IBHeadlines'))
const LazyMyIBData = React.lazy(() => import('./Components/Analyser/IB/Data/IBData'))
const LazyMyIBSubjects = React.lazy(() => import('./Components/Analyser/IB/Subjects/IBSubjects'))
const LazyMyIBCandidates = React.lazy(() => import('./Components/Analyser/IB/Candidates/IBCandidates'))
const LazyMyIBCore = React.lazy(() => import('./Components/Analyser/IB/Core/IBCore'))
const LazyMyIBShare = React.lazy(() => import('./Components/Analyser/IB/Share/IBShare'))
const LazyMySubscribePaddle = React.lazy(() => import('./Pages/Subscribe'))

Amplify.configure(aws_exports);
Auth.configure(aws_exports);

function App() {
  const { loggedIn, AssessLoggedInState, signOut } = useLoggedIn();
  const { colorMode, theme } = useColorMode();
  const {
    isBeta,
    fetchUserData,
  } = useUserData();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      await AssessLoggedInState();
      if (loggedIn) {
        await fetchUserData();
      }
      setIsLoading(false);
    };

    initializeApp();
  }, [loggedIn]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            <NavBar loggedIn={loggedIn} signOut={signOut} />

            <Container component="main" maxWidth="false">

              <InvigilationOptions />
              <CssBaseline />

              <React.Suspense fallback='Loading...'>
                <Routes>
                  <Route path="/about" element={<LazyAbout />} />
                  <Route path="/account" element={<LazyAccount />} />
                  <Route path="/aup" element={<LazyAup />} />
                  <Route path="/contact" element={<LazyContact />} />
                  <Route path="/cookies" element={<LazyCookies />} />
                  <Route path="/timer" element={<LazyCountdown />} />
                  <Route path="/guides" element={<LazyGuides />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/subscribe" element={<LazyMySubscribePaddle />} />
                  <Route path="/invigilation" element={<LazyInvigilation />} />
                  <Route path="/analyser" element={<AnalyserProtectedRoute component={LazyMyResults} />} />
                  <Route path="/analyser/ib/headlines" element={<AnalyserProtectedRoute component={LazyMyIBHeadlines} />} />
                  <Route path="/analyser/ib/data" element={<AnalyserProtectedRoute component={LazyMyIBData} />} />
                  <Route path="/analyser/ib/subjects" element={<AnalyserProtectedRoute component={LazyMyIBSubjects} />} />
                  <Route path="/analyser/ib/candidates" element={<AnalyserProtectedRoute component={LazyMyIBCandidates} />} />
                  <Route path="/analyser/ib/core" element={<AnalyserProtectedRoute component={LazyMyIBCore} />} />
                  <Route path="/analyser/ib/share" element={<AnalyserProtectedRoute component={LazyMyIBShare} />} />
                  <Route path="/analyser-success" element={<AnalyserSuccessPage />} />
                  <Route path="/analyser-cancel" element={<AnalyserCancelPage />} />
                  <Route
                    path="/scheduler/school_setup/components"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyComponents} />}
                  />
                  <Route
                    path="/scheduler/school_setup/invigilators"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyInvigilators} />}
                  />
                  <Route
                    path="/scheduler/school_setup/cycles"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyCycles} />}
                  />
                  <Route
                    path="/scheduler/school_setup/rooms"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyRooms} />}
                  />
                  <Route
                    path="/scheduler/school_setup/candidates"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyStudents} />}
                  />
                  <Route
                    path="/subjects"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMySubjects} />}
                  />
                  <Route
                    path="/scheduler/school_setup/qualifications"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyBoards} />}
                  />
                  <Route
                    path="/scheduler/my_exams"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyExams} />}
                  />
                  <Route
                    path="/scheduler/school_setup"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMySchoolSetup} />}
                  />
                  <Route
                    path="/scheduler/my_exams/:cycleId"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyIndividualCycle} />}
                  />
                  <Route
                    path="/Scheduler"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyMyScheduler} />}
                  />

                  <Route path="/privacy" element={<LazyPrivacy />} />
                  <Route path="/signin" element={<LazySignIn />} />
                  <Route path="/success" element={<LazySuccess />} />
                  <Route path="/support" element={<LazySupport />} />
                  <Route path="/terms" element={<LazyTerms />} />
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<LazyNotfound />} />
                  <Route path="/trial" element={<LazyTrial />} />
                  <Route path="/invigilation/saved" element={<LazyMyDisplayList />} />
                  <Route path="/print/candidate-labels"
                    element={<ProtectedRoute isBeta={isBeta} component={LazyPrintCandidateLabels} />} />
                </Routes>

              </React.Suspense>
            </Container>

            <CookieConsent
              location="bottom"
              buttonText="OK"
              cookieName="ExamDisplay"
              buttonStyle={{ backgroundColor: "#90BE6D", color: '#ffffff', fontSize: "13px" }}
              expires={150}
            >
              This website uses cookies. Please visit our <Link underline="none" component={RouterLink} to={`/Cookies`} color="primary">cookie policy</Link> to find out more.

            </CookieConsent>

          </LocalizationProvider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;