import { useAtom } from 'jotai';
import { preferencesAtom } from '../Jotai/Atoms/tempPrefsAtom';

export const usePreferences = () => {
    const [preferences, setPreferences] = useAtom(preferencesAtom);

    const handleToggleContrast = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            contrast: !prevPreferences.contrast,
        }));
    };

    const setContrastToFalse = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            contrast: false,
        }));
    };

    const handleSetShowSettingsPanel = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            showSettingsPanel: !prevPreferences.showSettingsPanel,
        }));
    };

    const setShowSettingsPanelToFalse = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            showSettingsPanel: false,
        }));
    };

    const handleSetShowArrangements = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            arrangements: !prevPreferences.arrangements,
        }));
    };

    const setShowArrangementsToFalse = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            arrangements: false,
        }));
    };

    const handleSetStartZoomFactor = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            startZoomFactor: value,
        }));
    };

    const handleZoomChange = (value) => {
        setPreferences((prevPreferences) => {
            let newZoom = prevPreferences.zoom + value;
            if (newZoom < 0) newZoom = 0;
            if (newZoom > 100) newZoom = 100;

            return {
                ...prevPreferences,
                zoom: newZoom,
            };
        });
    };

    const setZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            zoom: 1,
        }));
    };

    const handleDetailZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            detailZoom: prevPreferences.detailZoom + value,
        }));
    };

    const setDetailZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            detailZoom: 1,
        }));
    };

    const handleClockZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            clockZoom: prevPreferences.clockZoom + value,
        }));
    };

    const setClockZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            clockZoom: 1,
        }));
    };

    const handleStartClockZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            startClockZoom: prevPreferences.startClockZoom + value,
        }));
    };

    const setStartClockZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            startClockZoom: 1,
        }));
    };

    const handleTitleZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            titleZoom: prevPreferences.titleZoom + value,
        }));
    };

    const setTitleZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            titleZoom: 1,
        }));
    };

    const handleSubheaderZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            subheaderZoom: prevPreferences.subheaderZoom + value,
        }));
    };

    const setSubheaderZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            subheaderZoom: 0.8,
        }));
    };

    const handleTimeZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            timeZoom: prevPreferences.timeZoom + value,
        }));
    };

    const setTimeZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            timeZoom: 1,
        }));
    };

    const handleChipZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            chipZoom: prevPreferences.chipZoom + value,
        }));
    };

    const setChipZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            chipZoom: 0.5,
        }));
    };

    const handleReminderZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            reminderZoom: prevPreferences.reminderZoom + value,
        }));
    };

    const setReminderZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            reminderZoom: 1,
        }));
    };

    const handleNotesZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            notesZoom: prevPreferences.notesZoom + value,
        }));
    };

    const setNotesZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            notesZoom: 1,
        }));
    };

    const handleSupervisionZoomChange = (value) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            supervisionZoom: prevPreferences.supervisionZoom + value,
        }));
    };

    const setSupervisionZoomToOne = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            supervisionZoom: 1,
        }));
    };

    const handleSetShowNotes = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            showNotes: !prevPreferences.showNotes,
        }));
    };

    const handleSetShowSupervision = () => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            showSupervision: !prevPreferences.showSupervision,
        }));
    };

    // Add more preference-related functions as needed

    return {
        preferences,
        handleToggleContrast,
        setContrastToFalse,
        handleSetShowSettingsPanel,
        setShowSettingsPanelToFalse,
        handleSetStartZoomFactor,
        handleZoomChange,
        setZoomToOne,
        handleDetailZoomChange,
        setDetailZoomToOne,
        handleTitleZoomChange,
        setTitleZoomToOne,
        handleSubheaderZoomChange,
        setSubheaderZoomToOne,
        handleTimeZoomChange,
        setTimeZoomToOne,
        handleChipZoomChange,
        setChipZoomToOne,
        handleReminderZoomChange,
        setReminderZoomToOne,
        handleSetShowArrangements,
        setShowArrangementsToFalse,
        handleClockZoomChange,
        setClockZoomToOne,
        handleStartClockZoomChange,
        setStartClockZoomToOne,
        handleNotesZoomChange,
        setNotesZoomToOne,
        handleSupervisionZoomChange,
        setSupervisionZoomToOne,
        handleSetShowNotes,
        handleSetShowNotes,
        handleSetShowSupervision,
        // Return more functions as needed
    };
};
