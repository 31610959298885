import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { userPrefsAtom } from '../Jotai/Atoms/userPrefsAtom';
import { loadCustomAttributes } from '../Helpers/cognitoAttributes';

const defaultPrefs = [
  {
    key: 1,
    name: 'showEndings',
    checked: true,
  },
  {
    key: 2,
    name: 'showCountdown',
    checked: true,
  },
  {
    key: 3,
    name: 'showSeconds',
    checked: true,
  },
  {
    key: 4,
    name: 'showDividers',
    checked: true,
  },
  {
    key: 5,
    name: 'showFiveMins',
    checked: true,
  },
  {
    key: 6,
    name: 'showThirtyMins',
    checked: true,
  },
  {
    key: 7,
    name: 'showPauseControls',
    checked: false,
  },
  {
    key: 8,
    name: 'showGranularZoomControls',
    checked: false,
  },
  {
    key: 9,
    name: 'showStartTime',
    checked: true,
  },
  {
    key: 10,
    name: 'showCurrentTime',
    checked: true,
  },
  {
    key: 11,
    name: 'showExtraTimeControls',
    checked: true,
  },
  {
    key: 12,
    name: 'showQualification',
    checked: true,
  },
  {
    key: 13,
    name: 'showLevel',
    checked: true,
  },
  {
    key: 14,
    name: 'useSound',
    checked: false,
  },
  {
    key: 15,
    name: 'showNotes',
    checked: false,
  },
  {
    key: 16,
    name: 'showSupervision',
    checked: false,
  }
];

export default function useUserPreferences() {
  const [userPrefs, setUserPrefs] = useAtom(userPrefsAtom);

  useEffect(() => {
    const loadUserPrefsFromCognito = async () => {
      const customAttributes = await loadCustomAttributes();

      if (customAttributes.displayPrefs && JSON.parse(customAttributes.displayPrefs).length > 0) {
        const parsedPrefs = JSON.parse(customAttributes.displayPrefs);
        setUserPrefs((prevPrefs) => {
          const updatedPrefs = [...prevPrefs];
          defaultPrefs.forEach((defaultPref) => {
            const existingPref = updatedPrefs.find((pref) => pref.name === defaultPref.name);
            if (!existingPref) {
              updatedPrefs.push(defaultPref);
            }
          });
          parsedPrefs.forEach((parsedPref) => {
            const index = updatedPrefs.findIndex((pref) => pref.name === parsedPref.name);
            if (index !== -1) {
              updatedPrefs[index].checked = parsedPref.checked;
            }
          });
          return updatedPrefs;
        });
      } else {
        setUserPrefs((prevPrefs) => {
          const updatedPrefs = [...prevPrefs];
          defaultPrefs.forEach((defaultPref) => {
            const existingPref = updatedPrefs.find((pref) => pref.name === defaultPref.name);
            if (!existingPref) {
              updatedPrefs.push(defaultPref);
            }
          });
          return updatedPrefs;
        });
      }
    };

    loadUserPrefsFromCognito();
  }, [setUserPrefs]);

  const updateUserPrefs = (updater) => {
    setUserPrefs((prevPrefs) => {
      const updatedPrefs = updater(prevPrefs);
      return updatedPrefs;
    });
  };

  return { userPrefs, updateUserPrefs };
}