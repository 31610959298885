import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { v4 as uuidv4 } from 'uuid';

import { colorFavsAtom } from '../../../Jotai/Atoms/colorFavAtoms';

const selectedRowsAtom = atomWithStorage('SELECTED_ROWS', []);
const selectionModelAtom = atomWithStorage('SELECTION_MODEL', []);

export function useInvigilationActions() {
    const [selectedRows, setSelectedRows] = useAtom(selectedRowsAtom);
    const [selectionModel, setSelectionModel] = useAtom(selectionModelAtom);
    const [colorFavs] = useAtom(colorFavsAtom);

    const setRowDuration = (rowId, duration) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, duration } : row))
        );
    };

    const setRowSubject = (rowId, subject) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, subject } : row))
        );
    };

    const setRowLevel = (rowId, level) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, level } : row))
        );
    };

    const setRowExamType = (rowId, examType) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, examType } : row))
        );
    };

    const setRowPaperNumber = (rowId, paperNumber) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, paperNumber } : row))
        );
    };

    const setRowColor = (rowId, color) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, color } : row))
        );
    };

    const setRowCheckedStates = (rowId, checkedStates) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, checkedStates } : row))
        );
    };

    const setRowAutoStart = (rowId, autoStart) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, autoStart } : row))
        );
    };

    const setRowSupervision = (rowId, supervision) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, supervision } : row))
        );
    };

    const setRowNotes = (rowId, notes) => {
        setSelectedRows((prevRows) =>
            prevRows.map((row) => (row.id === rowId ? { ...row, notes } : row))
        );
    };

    const handleRemovePaperFromSelection = (paperID) => {
        const newSelectionModel = selectionModel.filter(paper => paper !== paperID);
        setSelectionModel(newSelectionModel);

        const newSelectedRows = selectedRows.filter(paper => paper.id !== paperID);
        setSelectedRows(newSelectedRows);
    };

    const moveItem = (index, direction) => {
        if (index < 0 || index >= selectedRows.length || (direction !== 'up' && direction !== 'down')) return;
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= selectedRows.length) return;

        const newSelectedRows = [...selectedRows];
        [newSelectedRows[index], newSelectedRows[newIndex]] = [newSelectedRows[newIndex], newSelectedRows[index]];
        setSelectedRows(newSelectedRows);
    };

    const moveItemUp = (index) => moveItem(index, 'up');
    const moveItemDown = (index) => moveItem(index, 'down');

    const addBlankPaper = () => {
        const defaultColors = ["#37517e", "#008fc9", "#2ead4b", "#c97500", "#cf2929"];

        let userFavoriteColors = colorFavs;

        userFavoriteColors = Array.isArray(userFavoriteColors) ? userFavoriteColors : [];

        const combinedColors = Array.from(new Set([...defaultColors, ...userFavoriteColors]));
        const randomColor = combinedColors[Math.floor(Math.random() * combinedColors.length)];

        const newPaper = {
            id: uuidv4(),
            paperNumber: 'Paper 1',
            duration: 60,
            subject: 'Subject',
            level: '',
            examType: '',
            checkedStates: { end: true },
            color: randomColor,
            supervision: { needed: false, time: 0 },
            notes: ''
        };

        setSelectedRows([...selectedRows, newPaper]);
    };

    return {
        selectedRows,
        selectionModel,
        setSelectedRows,
        setSelectionModel,
        handleRemovePaperFromSelection,
        moveItemUp,
        moveItemDown,
        addBlankPaper,
        setRowDuration,
        setRowSubject,
        setRowPaperNumber,
        setRowColor,
        setRowCheckedStates,
        setRowAutoStart,
        setRowLevel,
        setRowExamType,
        setRowSupervision,
        setRowNotes
    };
}
