// External Libraries
import { useEffect } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Stack, Switch, Typography } from '@mui/material';

// Icons
import { AccessAlarm, Monitor, NotificationsActive, Save, Tune, Notes, SupervisorAccount } from '@mui/icons-material';

// State
import { saveCustomAttributes } from '../../Helpers/cognitoAttributes';

// Local Components
import CustomDialogTitle from '../CustomDialogTitle';

// Hooks
import { usePreferences } from '../../hooks/usePreferences';
import useUserPreferences from '../../hooks/useUserPreferences';

function InvigilationOptions() {
  const { preferences, setShowSettingsPanelToFalse } = usePreferences();
  const { userPrefs, updateUserPrefs } = useUserPreferences();

  const { showSettingsPanel } = preferences;

  useEffect(() => {
    const saveUserPrefsToCognito = async () => {
      await saveCustomAttributes({ displayPrefs: JSON.stringify(userPrefs) });
    };

    saveUserPrefsToCognito();
  }, [userPrefs]);

  const handleUserPrefChecked = (event) => {
    const { name, checked } = event.target;
    updateUserPrefs((draft) =>
      draft.map((pref) => (pref.name === name ? { ...pref, checked } : pref))
    );
  };

  const renderPrefToggle = (name, label, color) => (
    <FormControlLabel
      control={
        <Switch
          name={name}
          checked={userPrefs.find((pref) => pref.name === name)?.checked || false}
          onChange={handleUserPrefChecked}
          color={color}
        />
      }
      label={label}
      labelPlacement="start"
      fontWeight="light"
    />
  );

  return (
    <Dialog open={showSettingsPanel} onClose={setShowSettingsPanelToFalse} fullWidth maxWidth='md'>
      <CustomDialogTitle title='Display Options' url='' />
      <DialogContent>
        <Alert severity="info" sx={{ mb: 2 }}>
          These settings are saved for your account and will be used across all displays, on all devices.
        </Alert>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} width={120}>
            <Monitor />
            <Typography sx={{ fontWeight: 600 }}>
              Display
            </Typography>
          </Stack>

          {renderPrefToggle('showStartTime', 'Start time', 'secondary')}
          {renderPrefToggle('showCurrentTime', 'Current time', 'secondary')}
          {renderPrefToggle('showQualification', 'Qualification', 'secondary')}
          {renderPrefToggle('showLevel', 'Level', 'secondary')}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} width={120}>
            <NotificationsActive />
            <Typography sx={{ fontWeight: 600 }}>
              Reminders
            </Typography>
          </Stack>

          {renderPrefToggle('showFiveMins', '5 mins', 'tertiary')}
          {renderPrefToggle('showThirtyMins', '30 mins', 'tertiary')}
          {renderPrefToggle('useSound', 'Sound', 'tertiary')}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} width={120}>
            <AccessAlarm />
            <Typography sx={{ fontWeight: 600 }}>
              Timers
            </Typography>
          </Stack>

          {renderPrefToggle('showSeconds', 'Seconds', 'success')}
          {renderPrefToggle('showEndings', 'End', 'success')}
          {renderPrefToggle('showCountdown', 'Countdown', 'success')}
          {renderPrefToggle('showDividers', 'Dividers', 'success')}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} width={120}>
            <Tune />
            <Typography sx={{ fontWeight: 600 }}>
              Controls
            </Typography>
          </Stack>

          {renderPrefToggle('showGranularZoomControls', 'Granular zoom controls', 'warning')}
          {renderPrefToggle('showPauseControls', 'Start/Pause controls', 'warning')}
          {renderPrefToggle('showExtraTimeControls', 'Extra time controls', 'warning')}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} width={120}>
            <Notes />
            <Typography sx={{ fontWeight: 600 }}>
              Additional
            </Typography>
          </Stack>

          {renderPrefToggle('showNotes', 'Notes', 'info')}
          {renderPrefToggle('showSupervision', 'Supervision', 'info')}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e5e5e5' }}>
        <Button
          onClick={setShowSettingsPanelToFalse}
          color="success"
          startIcon={<Save />}
          variant='contained'
        >
          Save and Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InvigilationOptions;
